import { Pipe, PipeTransform } from '@angular/core';

import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

@Pipe({
  name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {
  transform(input: any, replacement: string): string {
    //   console.log('INPUT', input);
    //   const date = new Date();
    //   date.setHours(date.getHours() - 24);
    //   console.log('NOW', date.toISOString());
    // if (input > date.toISOString()) {
    dayjs.extend(relativeTime);
    dayjs.extend(updateLocale);

    dayjs.updateLocale('en', {
      relativeTime: {
        future: ' %s ur',
        past: '%s\n nazaj',
        s: 'nekaj sekund',
        m: 'eno minuto',
        mm: function (number: any, _withoutSuffix: any, _key: any, _isFuture: any) {
          switch (number) {
            case 2:
              return number + ' minuti';
              break;
            case 3:
            case 4:
              return number + ' minute';
              break;
            default:
              return number + ' minut';
          }
        },
        h: 'ena ura',
        hh: function (number: any, _withoutSuffix: any, _key: any, _isFuture: any) {
          switch (number) {
            case 2:
              return number + ' uri';
              break;
            case 3:
            case 4:
              return number + ' ure';
              break;
            default:
              return number + ' ur';
          }
        },
        d: 'en dan',
        dd: function (number:any, _withoutSuffix: any, _key: any, _isFuture: any) {
          switch (number) {
            case 2:
              return number + ' dneva';
              break;
            case 3:
            case 4:
              return number + ' dni';
              break;
            default:
              return number + ' dni';
          }
        },
        M: 'en mesec',
        MM: function (number:any, _withoutSuffix: any, _key: any, _isFuture: any) {
          switch (number) {
          case 2:
            return number + ' mesca';
            break;
          case 3:
          case 4:
            return number + ' mesce';
            break;
          default:
            return number + ' mescev';
        }},
        y: 'eno leto',
        yy: function (number:any, _withoutSuffix: any, _key: any, _isFuture: any) {
          switch (number) {
          case 2:
            return number + ' leti';
            break;
          case 3:
          case 4:
            return number + ' leta';
            break;
          default:
            return number + ' let';
        }},
      },
    });
    // }

    if (input) {
      return dayjs(input).fromNow().replace('\n', replacement);
    }

    return '';
  }
}
