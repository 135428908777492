import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';

export function htmlFiles(url: UrlSegment[]) {
  return url.length === 1 && url[0].path.endsWith('.html')
    ? {
        consumed: url,
        posParams: {
          id: new UrlSegment(url[0].path, {}),
        },
      }
    : null;
}

const routes: Routes = [
  {
    matcher: htmlFiles,
    loadChildren: () =>
      import('./article/article.module').then((m) => m.ArticleModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./homepage/homepage.module').then((m) => m.HomepageModule),
  },/*
  {
    path: 'ga',
    loadChildren: () => import('./ga/ga.module').then((m) => m.GaModule),
  },
  {
    path: 'archive',
    loadChildren: () =>
      import('./archive/archive.module').then((m) => m.ArchiveModule),
  },
  {
    path: 'archive/:page',
    loadChildren: () =>
      import('./archive/archive.module').then((m) => m.ArchiveModule),
  },
  {
    path: 'webinars',
    loadChildren: () =>
      import('./_list/webinars.module').then((m) => m.WebinarsModule),
  },
  {
    path: 'negozio-premium',
    loadChildren: () =>
      import('./shop/shop.module').then((m) => m.ShopModule),
  }, */
  {
    path: ':param',
    loadChildren: () =>
      import('./category/category.module').then((m) => m.CategoryModule),
  },

  /*
  { path: 'sitemap', component: SitemapComponent },
  { path: ':param', component: PageComponent },
*/
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
