import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';


const service = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class ArticleService {

  constructor(private http: HttpClient) { }

  public search(search: any): any {
    return this.http.get<any>(service + '/v1/public/articles?search=' + search);  }

  public getArchive(cnt:any, offset: any): any {
    return this.http.get<any>(service + '/v1/public/articles?copyrighted=true&page_size=' + cnt + '&page_offset=' + offset);
  }
  public getCopyrighted(cnt: any, offset:any): any {
    return this.http.get<any>(service + '/v1/public/articles?copyrighted=true&special=false&page_size=' + cnt + '&page_offset=' + offset);
  }


}
