// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
   //  apiUrl: 'http://localhost:3000',
   //  cookieUrl: 'localhost',
   //docUrl: 'http://127.0.0.1:8887',

//  apiUrl: 'https://ftaapi.pesek.top',
//  docUrl: 'https://ftacdn.pesek.top'
    apiUrl: 'https://api.homeofbridge.com',
   docUrl: 'https://cdn.homeofbridge.com',
   /* stripe: {
    publish: 'pk_test_51MC2KSAQ9cUGccTaL44XML1sPxt9ZLRMkG9EG6DuMBc2lfP5beeQg6mzcQKoEo2Cy7SpVU88HNSFmiSs3BxXLvU700lZtJFEf5',
    secret: 'sk_test_51MC2KSAQ9cUGccTa33rHdHjGWwlw3X3QG74PjtUTSBArawiqWI2QZKPsc5bdvC83W8Oq4HV1xcXISnVKQhi9TMjg00wovBmTxK' */

}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
