import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {  Router } from '@angular/router';

import { environment } from './../environments/environment';



// tslint:disable-next-line:ban-types
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  //keep refs to subscriptions to be able to unsubscribe later


  title = 'hob-website';

  environment: boolean;
  isBrowser: boolean | undefined;

  constructor(
    public router: Router,
  ) {
    this.environment = !environment.production;
/*
    this.router.events.subscribe((event) => {
      this.isBrowser = isPlatformBrowser(platformId);


    }); */

  }

  ngOnInit() {
  }

  ngOnDestroy() {

  }
}
