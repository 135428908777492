import { ImgResponsive } from './imgresponsive.pipe';
import { BlankPipe } from './blank.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { JoinPipe } from './join.pipe';
import { RelativeTimePipe } from './dayjs.pipe';

@NgModule({
  declarations: [
    SanitizeHtmlPipe,
    JoinPipe,
    BlankPipe,
    RelativeTimePipe,
    ImgResponsive
  ],
  exports: [
    SanitizeHtmlPipe,
    JoinPipe,
    BlankPipe,
    RelativeTimePipe,
    ImgResponsive
  ],
  imports: [
    CommonModule
  ]

})
export class PipesModule { }
