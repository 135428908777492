import { Component, Input, OnInit } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';

// tslint:disable-next-line:ban-types

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() bookmarks: any;

  showSearch: any;
  showBookmarks: any;
  showMenu: any;

  searchText: any;
  searched: any;
  itemsString:any;
  items: any;
  submitted: boolean | undefined;
  articles: any;
  constructor(
    private articleService: ArticleService
  ) { }

  ngOnInit(): void {
  }

  public toStorage(_url: any): void {

  }

  onSubmit(): any {


    this.articleService.search(encodeURIComponent(this.searchText)).subscribe((articles:any) =>
      this.articles = articles
    );
  }

  trackByMethod(_index: number, el: any): number {
    return el.id;
  }


}
