import { HeaderComponent } from './parts/header/header.component';
import { FooterComponent } from './parts/footer/footer.component';
import { MenuComponent } from './parts/menu/menu.component';
import { NgModule } from '@angular/core';

import {
  BrowserModule, provideClientHydration
} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { PipesModule } from './pipes';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, MenuComponent],
  exports: [AppComponent],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    PipesModule,
  ],
  providers: [provideClientHydration()],
  bootstrap: [AppComponent],
})
export class AppModule {}
