<div class="logoresponsive">
  <span style="height:40px;display:inline-block;color:white; font-weight:bold; font-size:2rem;float:right">HoB</span>
  <!--  <a href="/" style="float:right">
      <img src="/assets/logo.webp" width="167" height="40" alt="HomeOfBridge">
    </a> -->
    </div>
<div style="clear:both"></div>
<div class="submenu posRel">
  <div class="content">

    <span class="right cursor" (click)="showSearch = !showSearch" *ngIf="!showSearch">
      <span class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" fill="rgba(255,255,255,1)" />
        </svg>
      </span>
    </span>
    <div *ngIf="showSearch right">
      <form (ngSubmit)="onSubmit()">
        <button type="submit" class="reset">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" fill="rgba(255,255,255,1)" />
          </svg>
        </button>
        <div class="right">
          <input type="text" placeholder="Išči po naslovih" [(ngModel)]="searchText" name="searchText">
        </div>
      </form>
    </div>
    <div *ngIf="showSearch" class="search greyLayer">
      <div class="right">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" (click)="showSearch=!showSearch" class="cursor">
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
        </svg>
      </div>
      <h3>Rezultati iskanja</h3>
      <div class="authors" *ngFor="let article of articles?.rows; trackBy: trackByMethod">
        <a class="normal" [innerHtml]="article.data.meta.title" [routerLink]="['/'+article.slug + '.html']" class="cursor"></a>
        <div class="small padB" [innerHtml]="article.published_at | relativeTime: ' ' | sanitizeHtml"></div>
      </div>
    </div>

    <div class="hide-xl">

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" (click)="showMenu=!showMenu" class="cursor">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" fill="rgba(255,255,255,1)" />
      </svg>
    </div>

    <div *ngIf="showMenu" class="blueLayer">


      <a [routerLink]="['/'+'konvencije']" (click)="showMenu=!showMenu">Konvencije</a>
      <a [routerLink]="['/'+'clanki']" (click)="showMenu=!showMenu">Članki</a>

    </div>

    <div class="hide-xs">
      <div class="menu">
        <ul>
          <li>
            <a [routerLink]="['/']" class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.49a1 1 0 0 1 .386-.79l8-6.222a1 1 0 0 1 1.228 0l8 6.222a1 1 0 0 1 .386.79V20zm-2-1V9.978l-7-5.444-7 5.444V19h14z" fill="rgba(255,255,255,1)" />
              </svg>
            </a>
          </li>
          <li><a [routerLink]="['/'+'konvencije']">Konvencije</a></li>
          <li><a [routerLink]="['/'+'clanki']">Članki</a></li>

        </ul>
      </div>

    </div>
  </div>
</div>
